
























































































import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { vxm } from '@/store'
import { appendSitePrefix } from '@/utils/routeUtils'

const TypeDeposit = 'Deposit'
const TypeWithdrawal = 'Withdrawal'
const TypeCashInDrawer = 'CashInDrawer'
const TypeCashToBank = 'CashToBank'
const TypeCashFromBank = 'CashFromBank'

@Component
export default class NewCashTransaction extends Vue {
  @Prop()
  private registerId: number

  private amount = ''
  private comment = ''
  private ingoingBalance = ''
  private canAdjustIngoingBalance = ''
  private register = null
  private attachment = null
  private error = ''
  private isLoading = true

  private type = ''
  private types = [
    {
      id: TypeDeposit,
      description: 'Deposit money into the cash drawer',
      amountInfoText: 'Amount that is added to the drawer',
    },
    {
      id: TypeWithdrawal,
      description: 'Withdraw money from the cash drawer',
      amountInfoText: 'Amount that is removed from the drawer',
    },
    {
      id: TypeCashToBank,
      description: 'Withdraw money and place in bank/safe',
      amountInfoText: 'Amount that is removed from the drawer',
    },
    {
      id: TypeCashFromBank,
      description: 'Deposit money from bank/safe',
      amountInfoText: 'Amount that is added to the drawer',
    },
    {
      id: TypeCashInDrawer,
      description: 'Register cash in drawer / change balance',
      amountInfoText: 'Actual amount observed in cash drawer',
    },
  ]

  public created(): void {
    this.$axios
      .get('/v4/site/payment/cash-registers/' + this.registerId + '/cash-balance')
      .then((response) => {
        this.register = response.data.data.register
        this.ingoingBalance = response.data.data.balances.outgoingBalance
        this.canAdjustIngoingBalance = response.data.data.balances.canAdjustIngoingBalance
        this.isLoading = false
      })
      .catch((err) => {
        vxm.alert.onAxiosError(err, 'Error fetching ingoing balance')
      })
  }

  private setType(type): void {
    this.type = type
  }

  private get title() {
    if (this.type) {
      return this.$t('c:cash-register:New ' + this.type.toLowerCase() + ' transaction')
    } else {
      return this.$t('c:cash-register:New cash balance transaction')
    }
  }

  private get registerName(): string {
    return this.register ? this.register.name : ''
  }

  private formatAmount(value: string, showSign = false): string {
    if (value === null) {
      return '?'
    }
    if (value === '') {
      return ''
    }
    const isNegative = value.match(/^-/)
    const formatted = parseFloat(value).toFixed(2)
    if (showSign && !isNegative) {
      return '+' + formatted
    } else {
      return formatted
    }
  }

  private validateAmount(amount: string): boolean {
    return !!amount.match(/^\d+(\.\d+)?$/)
  }

  private get changeAmount(): string {
    if (this.amount === '') {
      return ''
    }
    if (!this.validateAmount(this.amount)) {
      return null
    }
    switch (this.type) {
      case TypeDeposit:
      case TypeCashFromBank:
        return this.amount
      case TypeWithdrawal:
      case TypeCashToBank:
        return '' + (0 - parseFloat(this.amount))
      case TypeCashInDrawer:
        return '' + (parseFloat(this.amount) - parseFloat(this.ingoingBalance))
      default:
        console.error('Error deciding on changeAmount for type: ' + this.type + ' amount: ' + this.amount)
        return ''
    }
  }

  private get outgoingBalance(): string {
    if (this.amount === '') {
      return ''
    }
    if (!this.validateAmount(this.amount)) {
      return null
    }
    switch (this.type) {
      case TypeDeposit:
      case TypeCashFromBank:
        return '' + (parseFloat(this.ingoingBalance) + parseFloat(this.amount))
      case TypeWithdrawal:
      case TypeCashToBank:
        return '' + (parseFloat(this.ingoingBalance) - parseFloat(this.amount))
      case TypeCashInDrawer:
        return this.amount
      default:
        console.error('Error deciding on changeAmount for type: ' + this.type + ' amount: ' + this.amount)
        return ''
    }
  }

  private get amountInfoText(): string {
    for (let i = 0; i < this.types.length; i++) {
      if (this.types[i].id === this.type) {
        return this.$t('c:cash-register:' + this.types[i].amountInfoText)
      }
    }
    return ''
  }

  private get isCommentRequired() {
    const diff = Math.abs(parseFloat(this.outgoingBalance) - parseFloat(this.ingoingBalance))
    return this.type === TypeCashInDrawer && diff > 0.001
  }

  private get commentLabel() {
    let label = this.$t('Comment')
    label += ' (' + this.$t(this.isCommentRequired ? 'required' : 'optional') + ')'
    return label
  }

  private get errorTranslated(): string {
    if (!this.error || this.error === '') {
      return ''
    }
    return this.$t('c:cash-register:' + this.error)
  }

  private back() {
    this.amount = ''
    this.comment = ''
    this.attachment = null
    this.type = ''
  }

  private getAttachmentBase64(callback): void {
    if (!this.attachment) {
      callback(null)
      return
    }

    const reader = new FileReader()
    reader.readAsDataURL(this.attachment)
    reader.onload = function () {
      let encoded = reader.result.toString().replace(/^data:(.*,)?/, '')
      if (encoded.length % 4 > 0) {
        encoded += '='.repeat(4 - (encoded.length % 4))
      }
      callback(encoded)
    }
    reader.onerror = function (error) {
      vxm.alert.onAxiosError(error, 'Error getting attachment data')
    }
  }

  private save(): void {
    this.error = ''

    if (!this.amount) {
      this.error = 'Missing amount'
      return
    }

    if (!this.validateAmount(this.amount)) {
      this.error = 'Invalid amount'
      return
    }

    if (this.changeAmount === null) {
      this.error = 'Invalid amount'
      return
    }
    if (parseFloat(this.outgoingBalance) < 0) {
      this.error = 'Outgoing balance cannot be below 0'
      return
    }

    if (this.isCommentRequired && !this.comment) {
      this.error = 'Comment is required when adjusting incoming balance'
      return
    }

    this.isLoading = true

    this.getAttachmentBase64((base64Data) => {
      const data = {
        type: this.type,
        amount: this.amount,
        comment: this.comment,
        attachmentName: this.attachment ? this.attachment.name : '',
        attachmentData: base64Data || '',
      }

      this.$axios
        .post('/v4/site/payment/cash-registers/' + this.registerId + '/cash-transactions', data)
        .then((response) => {
          this.$emit('saved', response.data.data)
          this.isLoading = false
        })
        .catch((err) => {
          vxm.alert.onAxiosError(err, 'Error saving transaction')
          this.isLoading = false
        })
    })
  }

  private getTypeTitle(type: string): string {
    switch (type) {
      case 'DailyCashSales':
        return this.$t('c:cash-register:Cash sales turnover')
      case 'CashInDrawer':
        return this.$t('c:cash-register:Counted cash in drawer')
      case 'CashToBank':
        return this.$t('c:cash-register:Cash to bank')
      case 'CashFromBank':
        return this.$t('c:cash-register:Cash from bank')
      case 'EndOfDayBalance':
        return this.$t('c:cash-register:Outgoing cash balance')
      case 'IngoingBalance':
        return this.$t('c:cash-register:Ingoing cash balance')
      case 'Deposit':
        return this.$t('c:cash-register:Deposit')
      case 'Withdrawal':
        return this.$t('c:cash-register:Withdrawal')
    }
  }

  private getEndOfDayUrl(): string {
    return appendSitePrefix('/U/ecr/reconciliation')
  }

  private get prohibitCashInDrawer(): boolean {
    if (this.type !== TypeCashInDrawer) {
      return false
    }
    if (this.canAdjustIngoingBalance) {
      return false
    }
    return true
  }
}
