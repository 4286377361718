
























































import Vue from 'vue'
import Component from 'vue-class-component'
import ListView from '@/components/list/ListView.vue'
import { vxm } from '@/store'
import { Watch } from 'vue-property-decorator'
import NewCashTransaction from '@/components/payment/cash-register/NewCashTransaction.vue'

@Component({
  components: {
    ListView,
    NewCashTransaction,
  },
})
export default class List extends Vue {
  private isReady = false
  private registerId: number = null
  private registers: Array<Record<string, unknown>> = []
  private topActions: Array<Record<string, unknown>> = []
  private showOnlyManual = true
  private showOnlyManualOptions = []
  private newDialogVisible = false
  private newDialogKey = 1
  private addedTransactionId = 0

  private created() {
    this.showOnlyManualOptions = [
      { id: true, name: this.$t('c:cash-register:Only manual transactions') },
      { id: false, name: this.$t('c:cash-register:All transactions') },
    ]
    this.topActions = [
      {
        id: 'new',
        click: () => {
          if (!this.registerId) {
            alert(this.$t('c:cash-register:Must choose a register first'))
            return
          }
          this.newDialogKey++
          this.newDialogVisible = true
        },
      },
    ]
    this.loadRegisters(() => {
      this.onRouteChange()
    })
  }

  private get url() {
    if (!this.registerId) {
      return ''
    }
    return '/v4/site/payment/cash-registers/' + this.registerId + '/cash-transactions'
  }

  private get query() {
    return {
      registerId: this.registerId,
      showOnlyManual: this.showOnlyManual,
      _reload: this.addedTransactionId, // just to force reload after saved transaction
    }
  }

  @Watch('$route', { deep: true })
  private onRouteChange() {
    if (this.$route.query.registerId !== undefined) {
      this.registerId = parseInt(this.$route.query.registerId as string)
    } else {
      this.registerId = null
    }
    if (this.$route.query.showOnlyManual !== undefined) {
      this.showOnlyManual = this.$route.query.showOnlyManual + '' === 'true'
    } else {
      this.showOnlyManual = true
    }
  }

  private get headers() {
    const headers = [
      {
        text: 'Time',
        value: 'createdAt',
        // filter: { focus: true, disable: false },
      },
      {
        text: 'Type',
        value: 'type',
        // filter operations on enums doesn't work properly yet - it doesn't to "contains" by default as others do
        filter: { disable: true },
      },
      {
        text: 'Amount (change)',
        value: 'amount',
        filter: { disable: true },
      },
    ]
    if (!this.showOnlyManual) {
      headers.push({
        text: 'Reconciled balance',
        value: 'balance',
        filter: { disable: true },
      })
    }
    const moreHeaders = [
      {
        text: 'Comment',
        value: 'comment',
      },
      {
        text: 'Created by',
        value: 'createdBy',
        filter: { disable: true },
      },
      {
        text: 'Attachment',
        value: 'fileUrl',
        filter: { disable: true },
      },
      {
        text: 'Z-report',
        value: 'zReportNumber',
        filter: { disable: true },
      },
    ]
    for (const h of moreHeaders) {
      headers.push(h)
    }
    return headers
  }

  private loadRegisters(callback) {
    this.$axios
      .get('/v4/site/payment/cash-registers')
      .then((response) => {
        this.registers = response.data.data
        this.isReady = true
        callback()
      })
      .catch((err) => {
        vxm.alert.onAxiosError(err, 'Failed to load list of cash registers')
      })
  }

  private onSavedTransaction(tx: Record<string, unknown>): void {
    this.addedTransactionId = tx.id as number
    this.newDialogVisible = false
  }

  private getTypeTitle(type: string): string {
    switch (type) {
      case 'DailyCashSales':
        return this.$t('c:cash-register:Cash sales turnover')
      case 'CashInDrawer':
        return this.$t('c:cash-register:Counted cash in drawer')
      case 'CashToBank':
        return this.$t('c:cash-register:Cash to bank')
      case 'CashFromBank':
        return this.$t('c:cash-register:Cash from bank')
      case 'EndOfDayBalance':
        return this.$t('c:cash-register:Outgoing cash balance')
      case 'IngoingBalance':
        return this.$t('c:cash-register:Ingoing cash balance')
      case 'Deposit':
        return this.$t('c:cash-register:Deposit')
      case 'Withdrawal':
        return this.$t('c:cash-register:Withdrawal')
    }
  }
}
